/**
 * Created by stapletont on 06/03/2015.
 */
define(['app'], function (app) {

  var _kibanaJavaScript = {

    _init: function () {
      app.subscribe('javascript/error', function () {
        var args = Array.prototype.slice.call(arguments, 0);
        args.push('error');
        _kibanaJavaScript._record.apply(_kibanaJavaScript, args);
      });

      app.subscribe('javascript/warning', function () {
        var args = Array.prototype.slice.call(arguments, 0);
        args.push('warning');
        _kibanaJavaScript._record.apply(_kibanaJavaScript, args);
      });

      window.onerror = function (e) {
        _kibanaJavaScript._record('window error', 'page', {message: e, stack: 'none'}, 'error');
      }
    },

    /**
     * Send JavaScript warning/error to Elastic Search API
     * @param {object} object
     * @private
     */
    _send: function (object) {
      // TODO add API end point
      //console.log(object);
      /*app.ajax.call({
       url: *//*'http://gbllog009.thehut.local:9200/logstash-2015.03.10/_search'*//* '',
       type: "POST",
       send: JSON.stringify(object),
       dataType: 'JSON'
       });*/
    },

    /**
     * Set object to record
     * @param {string} file
     * @param {string} section
     * @param {object} e
     * @param {string} type
     * @private
     */
    _record: function (file, section, e, type) {
      this._send({
        file: file,
        section: section,
        message: e.message,
        stack: e.stack,
        type: type,
        page: window.location.href
      });
    }
  };
  _kibanaJavaScript._init();
  return _kibanaJavaScript;
});
